.linkButton,
.linkButton:link,
.linkButton:visited {
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
  width: 100px;
  height: 30px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #007bff;
  border-radius: 8px;
  background-color: #e2e2e2;
}

.linkButton:hover,
.linkButton:active {
  background-color: #007bff;
  border-color: #b9b9b9;
  color: #f3f3f3;
}

.linkButton__icon {
  margin-right: 12px;
}

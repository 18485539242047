.namedIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  padding: 0 5px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #444444;
  letter-spacing: 1px;
  transition: all 0.2s;
  height: 100%;
  text-align: center;
}

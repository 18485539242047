.footer {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111;
  padding: 40px 0;
}

.footer__container {
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__text {
  color: lightgray;
  margin-top: 24px;
}

.image_text {
  font-size: 10px;
  text-align: center;
}

.lightgray {
  color: lightgray;
}

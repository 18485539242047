/* home styles */

#home {
  position: relative;
  width: 100%;
  padding-top: 80px;
  height: calc(100vh - 80px);
  min-height: 700px;
  background-color: #f8f9fa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/backgrounds/img1.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.home__wrapper {
  font-family: "Lato", sans-serif;
  width: 60vw;
  max-width: 1170px;
  min-width: 300px;
  padding-left: 15px;
  height: 420px;
  font-size: 18px;
  text-shadow: 0 0 3px black;
}

.home__name {
  font-family: "Open Sans", sans-serif;
  font-size: 64px;
  letter-spacing: 10px;
  line-height: 1.2;
  font-weight: 700;
}

.home__description {
  margin-top: 4px;
  letter-spacing: 1px;
}

.home__links {
  margin-top: 20px;
}

.strong_text {
  font-weight: 600;
}

.orange {
  color: orange;
}

.blue {
  color: lightskyblue;
}

.green {
  color: limegreen;
}

#anchor_header,
#anchor_background,
#anchor_home {
  position: absolute;
  width: 1px;
  height: 1px;
  bottom: 80px;
  left: 80px;
}

#anchor_header {
  top: 80px;
}

#anchor_background {
  bottom: 2px;
}

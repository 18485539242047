* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

/* anchors */

#anchor_about,
#anchor_projects,
#anchor_skills,
#anchor_contact {
  position: absolute;
  width: 1px;
  height: 1px;
  bottom: 80px;
  left: 80px;
}

.app__loading {
  width: 100vw;
  height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

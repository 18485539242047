#contact {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact__container {
  transition: all 0.2s;
  width: 90%;
  max-width: 1170px;
  min-width: 330px;
  padding: 115px 15px 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 890px) {
  .contact__container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

#header {
  height: 80px;
  width: 100%;
  background-color: #14151690;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 60vw; */
  /* max-width: 1170px; */
  width: 1170px;
}

.header__logo {
  transition: 0.5s;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  border-right: 3px solid transparent;
}

.header__logo:hover {
  transition: 0.5s;
  transform: rotate(-90deg);
  border-right: 3px solid goldenrod;
}

.header__buttons {
  width: 50%;
  min-width: 460px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f3f3f3;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 50px; */
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  transition: all 0.3s;
  background: #666;
  border-radius: 4px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f3f3f3;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 48px;
  width: 48px;
  margin-right: 10%;
  margin-top: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  /* width: 150px;
  background-color: rgba(20, 21, 22, 0.565); */
}

.close_icon {
  color: #f3f3f3;
}

/* General sidebar styles */
.bm-menu {
  top: 0;
  right: 0;
}

/* Wrapper for item list */
.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 90%;
  height: 50% !important;
  padding-top: 50%;
  padding-right: 10%;
}

/* Styling of overlay */
.bm-overlay {
  top: 0;
  right: 0;
}

@media screen and (max-width: 1200px) {
  .header__wrapper {
    width: 962px;
  }
}

@media screen and (max-width: 992px) {
  .header__wrapper {
    width: 738px;
  }
}

@media screen and (max-width: 768px) {
  #header {
    width: 100vw;
    min-width: 360px;
  }

  .header__wrapper {
    width: 90%;
  }
}

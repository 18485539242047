#skills {
  position: relative;
  width: 100%;
  min-width: 360px;
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.skills__title {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 800;
  color: #252525;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
  line-height: 1;
}

.skills__subTitle {
  width: 90%;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  color: #666;
  margin-bottom: 40px;
  margin-top: 12px;
}

.skills__list {
  max-width: 1200px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  transition: all 0.2s;
  justify-content: center;
}

.skills__element {
  transition: all 0.2s;
  width: 25%;
  min-width: 235px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  margin-bottom: 20px;
}

@media screen and (max-width: 1060px) {
  .skills__element {
    flex: 1;
  }
}

@media screen and (max-width: 523px) {
  .skills__element {
    flex: 1;
    min-width: 162px;
  }
}

.projectCard {
  width: 100%;
  max-width: 340px;
  min-width: 310px;
  height: 370px;
  padding: 0 10px;
  margin: 15px;
  background-color: #f3f3f3;
  border-radius: 8px;
}

.projectCard__name {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #444444;
}

.projectCard__screenshot {
  height: 190px;
  width: 100%;
  overflow: hidden;
}

.projectCard__image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.4s;
}

.projectCard__screenshot:hover .projectCard__image {
  transform: scale(1.2);
}

.projectCard__tech {
  height: 50px;
  padding: 5px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.projectCard__buttons {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contactInfo {
  max-width: 420px;
  /* min-width: 340px; */
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactInfo__title {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 45px;
  color: #222;
  margin-bottom: 15px;
  padding-top: 15px;
}

.contactInfo__subtitle {
  color: #666;
  font-weight: 500;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 40px;
}

.contactInfo__infoBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  height: 50px;
}

.contactInfo__icon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsl(211, 100%, 50%);
  color: #007bff;
  font-size: 28px;
}

.contactInfo__infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 50px;
  margin-left: 12px;
}

.contactInfo__infoTitle {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  letter-spacing: 0.5px;
}

.contactInfo__info {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8a8a8a;
}

.contactForm {
  max-width: 670px;
  width: 100%;
  padding: 15px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactForm__inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactForm__inputBlock {
  flex: 1;
  display: flex;
}

.contactForm__input {
  overflow: auto;
  /* resize: vertical; */
  border: 1px solid #ddd;
  padding: 20px;
  margin: 15px;
  flex: 1;
  border-radius: 3px;
}

.contactForm__messageBlock {
  display: flex;
  width: 100%;
}

.contactForm__textarea {
  overflow: auto;
  resize: vertical;
  border: 1px solid #ddd;
  padding: 25px;
  width: 100%;
  min-height: 128px;
  margin: 15px;
  border-radius: 3px;
}

.contactForm__input::placeholder,
.contactForm__textarea::placeholder,
.contactForm__input,
.contactForm__textarea {
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.contactForm__button,
.contactForm__button:link,
.contactForm__button:visited {
  transition: all 0.4s ease-in-out;
  border-radius: 3px;
  border: 1px solid #666;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  font-size: 18px;
  padding: 12px;
  margin: 15px;
}

.contactForm__button:hover,
.contactForm__button:active {
  border: 1px solid #007bff;
  color: #007bff;
}

.contactForm__buttonIcon {
  margin-right: 12px;
}

.contactForm__title {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
}

.title_style {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 45px;
  color: #222;
}

@media screen and (max-width: 1015px) {
  .contactForm {
    width: 90%;
  }
  .contactForm__inputs {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .contactForm__inputBlock {
    width: 100%;
  }
}

/* 
.contactForm {
  width: 670px;
  padding: 15px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.contactForm__inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactForm__input {
  overflow: auto;
  resize: vertical;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 15px;
  width: 100%;
  border-radius: 3px;
}

.contactForm__textarea {
  overflow: auto;
  resize: vertical;
  border: 1px solid #ddd;
  padding: 25px;
  width: 100%;
  min-height: 128px;
  margin: 15px;
  border-radius: 3px;
}

.contactForm__input::placeholder,
.contactForm__textarea::placeholder,
.contactForm__input,
.contactForm__textarea {
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}



.contactForm__button,
.contactForm__button:link,
.contactForm__button:visited {
  transition: all 0.4s ease-in-out;
  border-radius: 3px;
  border: 1px solid #666;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  font-size: 18px;
  padding: 12px;
  margin: 15px;
}

.contactForm__button:hover,
.contactForm__button:active {
  border: 1px solid #007bff;
  color: #007bff;
}

.contactForm__buttonIcon {
  margin-right: 12px;
}

.contactForm__title {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
}

.title_style {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 45px;
  color: #222;
} */

.menu_button {
  position: relative;
  background: transparent;
  border: none;
  font-size: 15px;
  /* color: #fff; */
  padding: 8px;
  margin: 0 4px;
  /* width: 200px; */
  text-align: center;
  /* Safari */
  /* transition-duration: 0.4s; */
  /* -webkit-transition-duration: 0.4s; */
  transition: all ease-in-out 0.4s;
  font-weight: 600;
  letter-spacing: 2px;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border-radius: 11px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

/* .menu_button:after {
  content: "";
  background: #8b0000;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.menu_button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
} */

.menu_button:hover {
  border-top: 2px solid #daa520;
  /* background-color: rgba(218, 165, 32, 0.15); */

  background-image: linear-gradient(
    180deg,
    rgba(218, 165, 32, 0.8) 0%,
    rgba(218, 165, 32, 0.5) 10%,
    rgba(218, 165, 32, 0.2) 20%,
    rgba(218, 165, 32, 0) 45%
  );
  text-shadow: 0 2px 2px black;
  color: #f3f3f3 !important;
}

.button_active {
  border-bottom: 2px solid #daa520;
  /* text-shadow: 0 -2px 2px #daa520; */
}

.button_active:hover {
  border-bottom: 2px solid transparent;
  text-shadow: 0 2px 2px black;
}

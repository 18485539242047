#projects {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 48px;
  background-color: #f8f9fa;
  padding-top: 24px;
}

.projects__title {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 800;
  color: #252525;
}

.projects__list {
  width: 1170px;
  padding: 15px;
  transition: all 0.2s;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;
  align-items: center; */
}

@media screen and (max-width: 1200px) {
  .projects__list {
    width: 780px;
  }
}

@media screen and (max-width: 810px) {
  .projects__list {
    width: 390px;
  }
}

@media screen and (max-width: 576px) {
  .projects__list {
    padding: 15px 0;
    width: 360px;
  }
}

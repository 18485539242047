.links,
.links:link,
.links:visited {
  color: #fff;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  margin: 0 48px;
}

.links:hover,
.links:active {
  color: #007bff;
}

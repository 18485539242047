.resume__button,
.resume__button:link,
.resume__button:visited {
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  font-size: 22px;
  /* width: 100px; */
  height: 30px;
  padding: 12px;
}

.resume__button:hover,
.resume__button:active {
  color: #007bff;
}

.resume__icon {
  margin-right: 12px;
}

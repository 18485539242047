body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

/* anchors */

#anchor_about,
#anchor_projects,
#anchor_skills,
#anchor_contact {
  position: absolute;
  width: 1px;
  height: 1px;
  bottom: 80px;
  left: 80px;
}

.app__loading {
  width: 100vw;
  height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links,
.links:link,
.links:visited {
  color: #fff;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  margin: 0 48px;
}

.links:hover,
.links:active {
  color: #007bff;
}

.resume__button,
.resume__button:link,
.resume__button:visited {
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  font-size: 22px;
  /* width: 100px; */
  height: 30px;
  padding: 12px;
}

.resume__button:hover,
.resume__button:active {
  color: #007bff;
}

.resume__icon {
  margin-right: 12px;
}

#about {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.about__wrapper {
  /* width: 60vw; */
  /* max-width: 1170px; */
  width: 1170px;
  height: 85%;
  padding: 4% 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 27px;
  color: #666;
}

.about__info {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.about__image {
  /* width: 36%; */
  max-width: 400px;
  border-radius: 16px;
  align-self: flex-start;
  transition: all 0.2s;
}

.about__connections {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.about__data {
  margin-left: 16px;
  margin-top: 24px;
  margin-right: 16px;
  line-height: normal;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.about__icons {
  font-size: 20px;
  margin-right: 12px;
}

.icon_mod {
  margin-left: 3.5px;
  margin-right: 16px;
}

.about__description {
  /* width: 60%; */
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 100%;
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 27px;
  color: #666; */
}

.about__welcome {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #252525;
  margin-bottom: 15px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.about__commodoreLogo {
  height: 16px;
  vertical-align: middle;
}

.about__description p {
  margin-bottom: 15px;
}

/* links */

.text_links,
.text_links:link,
.text_links:visited {
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #8a8a8a;
}

.text_links:hover,
.text_links:active {
  color: #007bff;
}

.about__resume {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__links {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gray {
  color: gray;
}

@media screen and (max-width: 1200px) {
  .about__wrapper {
    width: 962px;
  }
  .about__image {
    max-width: 265px;
  }
}

@media screen and (max-width: 992px) {
  #about {
    justify-content: flex-start;
    align-items: center;
  }

  .about__wrapper {
    width: 738px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .about__info {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }

  .about__image {
    max-width: 400px;
    margin-bottom: 24px;
  }

  .about__connections {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .about__description {
    margin: 24px 0;
    width: 100%;
  }

  .about__resume {
    justify-content: space-evenly;
  }

  .about__welcome {
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .about__wrapper {
    width: 100vw;
    min-width: 360px;
    padding: 15px 0 0;
  }
  .about__image {
    width: 90%;
  }
  .about__description {
    width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .about__resume {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}

.contactForm {
  max-width: 670px;
  width: 100%;
  padding: 15px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactForm__inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactForm__inputBlock {
  flex: 1 1;
  display: flex;
}

.contactForm__input {
  overflow: auto;
  /* resize: vertical; */
  border: 1px solid #ddd;
  padding: 20px;
  margin: 15px;
  flex: 1 1;
  border-radius: 3px;
}

.contactForm__messageBlock {
  display: flex;
  width: 100%;
}

.contactForm__textarea {
  overflow: auto;
  resize: vertical;
  border: 1px solid #ddd;
  padding: 25px;
  width: 100%;
  min-height: 128px;
  margin: 15px;
  border-radius: 3px;
}

.contactForm__input::-webkit-input-placeholder, .contactForm__textarea::-webkit-input-placeholder {
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.contactForm__input:-ms-input-placeholder, .contactForm__textarea:-ms-input-placeholder {
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.contactForm__input::placeholder,
.contactForm__textarea::placeholder,
.contactForm__input,
.contactForm__textarea {
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.contactForm__button,
.contactForm__button:link,
.contactForm__button:visited {
  transition: all 0.4s ease-in-out;
  border-radius: 3px;
  border: 1px solid #666;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  font-size: 18px;
  padding: 12px;
  margin: 15px;
}

.contactForm__button:hover,
.contactForm__button:active {
  border: 1px solid #007bff;
  color: #007bff;
}

.contactForm__buttonIcon {
  margin-right: 12px;
}

.contactForm__title {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
}

.title_style {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 45px;
  color: #222;
}

@media screen and (max-width: 1015px) {
  .contactForm {
    width: 90%;
  }
  .contactForm__inputs {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .contactForm__inputBlock {
    width: 100%;
  }
}

/* 
.contactForm {
  width: 670px;
  padding: 15px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.contactForm__inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactForm__input {
  overflow: auto;
  resize: vertical;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 15px;
  width: 100%;
  border-radius: 3px;
}

.contactForm__textarea {
  overflow: auto;
  resize: vertical;
  border: 1px solid #ddd;
  padding: 25px;
  width: 100%;
  min-height: 128px;
  margin: 15px;
  border-radius: 3px;
}

.contactForm__input::placeholder,
.contactForm__textarea::placeholder,
.contactForm__input,
.contactForm__textarea {
  color: #111111;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}



.contactForm__button,
.contactForm__button:link,
.contactForm__button:visited {
  transition: all 0.4s ease-in-out;
  border-radius: 3px;
  border: 1px solid #666;
  cursor: pointer;
  text-decoration: none;
  color: #666;
  font-size: 18px;
  padding: 12px;
  margin: 15px;
}

.contactForm__button:hover,
.contactForm__button:active {
  border: 1px solid #007bff;
  color: #007bff;
}

.contactForm__buttonIcon {
  margin-right: 12px;
}

.contactForm__title {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
}

.title_style {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 45px;
  color: #222;
} */

.contactInfo {
  max-width: 420px;
  /* min-width: 340px; */
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactInfo__title {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 900;
  font-size: 45px;
  color: #222;
  margin-bottom: 15px;
  padding-top: 15px;
}

.contactInfo__subtitle {
  color: #666;
  font-weight: 500;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 40px;
}

.contactInfo__infoBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  height: 50px;
}

.contactInfo__icon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid hsl(211, 100%, 50%);
  color: #007bff;
  font-size: 28px;
}

.contactInfo__infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 50px;
  margin-left: 12px;
}

.contactInfo__infoTitle {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  letter-spacing: 0.5px;
}

.contactInfo__info {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8a8a8a;
}

.footer {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111;
  padding: 40px 0;
}

.footer__container {
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__text {
  color: lightgray;
  margin-top: 24px;
}

.image_text {
  font-size: 10px;
  text-align: center;
}

.lightgray {
  color: lightgray;
}

#contact {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact__container {
  transition: all 0.2s;
  width: 90%;
  max-width: 1170px;
  min-width: 330px;
  padding: 115px 15px 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 890px) {
  .contact__container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.menu_button {
  position: relative;
  background: transparent;
  border: none;
  font-size: 15px;
  /* color: #fff; */
  padding: 8px;
  margin: 0 4px;
  /* width: 200px; */
  text-align: center;
  /* Safari */
  /* transition-duration: 0.4s; */
  /* -webkit-transition-duration: 0.4s; */
  transition: all ease-in-out 0.4s;
  font-weight: 600;
  letter-spacing: 2px;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border-radius: 11px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

/* .menu_button:after {
  content: "";
  background: #8b0000;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.menu_button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
} */

.menu_button:hover {
  border-top: 2px solid #daa520;
  /* background-color: rgba(218, 165, 32, 0.15); */

  background-image: linear-gradient(
    180deg,
    rgba(218, 165, 32, 0.8) 0%,
    rgba(218, 165, 32, 0.5) 10%,
    rgba(218, 165, 32, 0.2) 20%,
    rgba(218, 165, 32, 0) 45%
  );
  text-shadow: 0 2px 2px black;
  color: #f3f3f3 !important;
}

.button_active {
  border-bottom: 2px solid #daa520;
  /* text-shadow: 0 -2px 2px #daa520; */
}

.button_active:hover {
  border-bottom: 2px solid transparent;
  text-shadow: 0 2px 2px black;
}

#header {
  height: 80px;
  width: 100%;
  background-color: #14151690;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 60vw; */
  /* max-width: 1170px; */
  width: 1170px;
}

.header__logo {
  transition: 0.5s;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  border-right: 3px solid transparent;
}

.header__logo:hover {
  transition: 0.5s;
  transform: rotate(-90deg);
  border-right: 3px solid goldenrod;
}

.header__buttons {
  width: 50%;
  min-width: 460px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f3f3f3;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 50px; */
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  transition: all 0.3s;
  background: #666;
  border-radius: 4px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f3f3f3;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 48px;
  width: 48px;
  margin-right: 10%;
  margin-top: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  /* width: 150px;
  background-color: rgba(20, 21, 22, 0.565); */
}

.close_icon {
  color: #f3f3f3;
}

/* General sidebar styles */
.bm-menu {
  top: 0;
  right: 0;
}

/* Wrapper for item list */
.bm-item-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 90%;
  height: 50% !important;
  padding-top: 50%;
  padding-right: 10%;
}

/* Styling of overlay */
.bm-overlay {
  top: 0;
  right: 0;
}

@media screen and (max-width: 1200px) {
  .header__wrapper {
    width: 962px;
  }
}

@media screen and (max-width: 992px) {
  .header__wrapper {
    width: 738px;
  }
}

@media screen and (max-width: 768px) {
  #header {
    width: 100vw;
    min-width: 360px;
  }

  .header__wrapper {
    width: 90%;
  }
}

.MobileMenu {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileMenu__MenuIcon,
.MobileMenu__closeIcon {
  font-size: 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.MobileMenu__MenuIcon {
  color: #f3f3f3;
}

.MobileMenu__Overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.3);
}

.MobileMenu__MenuWrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  width: 150px;
  height: 100vh;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.hide {
  transform: translate3d(100%, 0, 0);
}

.MobileMenu__close,
.MobileMenu__ButtonList {
  margin-right: 5vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.MobileMenu__ButtonList {
  flex-direction: column;
  align-items: flex-end;
}

/* home styles */

#home {
  position: relative;
  width: 100%;
  padding-top: 80px;
  height: calc(100vh - 80px);
  min-height: 700px;
  background-color: #f8f9fa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(/static/media/img1.c9bfb59e.jpg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.home__wrapper {
  font-family: "Lato", sans-serif;
  width: 60vw;
  max-width: 1170px;
  min-width: 300px;
  padding-left: 15px;
  height: 420px;
  font-size: 18px;
  text-shadow: 0 0 3px black;
}

.home__name {
  font-family: "Open Sans", sans-serif;
  font-size: 64px;
  letter-spacing: 10px;
  line-height: 1.2;
  font-weight: 700;
}

.home__description {
  margin-top: 4px;
  letter-spacing: 1px;
}

.home__links {
  margin-top: 20px;
}

.strong_text {
  font-weight: 600;
}

.orange {
  color: orange;
}

.blue {
  color: lightskyblue;
}

.green {
  color: limegreen;
}

#anchor_header,
#anchor_background,
#anchor_home {
  position: absolute;
  width: 1px;
  height: 1px;
  bottom: 80px;
  left: 80px;
}

#anchor_header {
  top: 80px;
}

#anchor_background {
  bottom: 2px;
}

.linkButton,
.linkButton:link,
.linkButton:visited {
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
  width: 100px;
  height: 30px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #007bff;
  border-radius: 8px;
  background-color: #e2e2e2;
}

.linkButton:hover,
.linkButton:active {
  background-color: #007bff;
  border-color: #b9b9b9;
  color: #f3f3f3;
}

.linkButton__icon {
  margin-right: 12px;
}

.namedIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  padding: 0 5px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #444444;
  letter-spacing: 1px;
  transition: all 0.2s;
  height: 100%;
  text-align: center;
}

.projectCard {
  width: 100%;
  max-width: 340px;
  min-width: 310px;
  height: 370px;
  padding: 0 10px;
  margin: 15px;
  background-color: #f3f3f3;
  border-radius: 8px;
}

.projectCard__name {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #444444;
}

.projectCard__screenshot {
  height: 190px;
  width: 100%;
  overflow: hidden;
}

.projectCard__image {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.4s;
}

.projectCard__screenshot:hover .projectCard__image {
  transform: scale(1.2);
}

.projectCard__tech {
  height: 50px;
  padding: 5px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.projectCard__buttons {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#projects {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 48px;
  background-color: #f8f9fa;
  padding-top: 24px;
}

.projects__title {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 800;
  color: #252525;
}

.projects__list {
  width: 1170px;
  padding: 15px;
  transition: all 0.2s;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;
  align-items: center; */
}

@media screen and (max-width: 1200px) {
  .projects__list {
    width: 780px;
  }
}

@media screen and (max-width: 810px) {
  .projects__list {
    width: 390px;
  }
}

@media screen and (max-width: 576px) {
  .projects__list {
    padding: 15px 0;
    width: 360px;
  }
}

#skills {
  position: relative;
  width: 100%;
  min-width: 360px;
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.skills__title {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 45px;
  font-weight: 800;
  color: #252525;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
  line-height: 1;
}

.skills__subTitle {
  width: 90%;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  color: #666;
  margin-bottom: 40px;
  margin-top: 12px;
}

.skills__list {
  max-width: 1200px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  transition: all 0.2s;
  justify-content: center;
}

.skills__element {
  transition: all 0.2s;
  width: 25%;
  min-width: 235px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  margin-bottom: 20px;
}

@media screen and (max-width: 1060px) {
  .skills__element {
    flex: 1 1;
  }
}

@media screen and (max-width: 523px) {
  .skills__element {
    flex: 1 1;
    min-width: 162px;
  }
}

.spinner {
  color: #007bff;
  font-size: 48px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  animation: loader 1.7s infinite ease, round 1.7s infinite ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner__logo {
  height: 100%;
  width: 100%;
  animation: round 1.7s infinite ease;
}
@keyframes loader {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


#about {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.about__wrapper {
  /* width: 60vw; */
  /* max-width: 1170px; */
  width: 1170px;
  height: 85%;
  padding: 4% 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 27px;
  color: #666;
}

.about__info {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.about__image {
  /* width: 36%; */
  max-width: 400px;
  border-radius: 16px;
  align-self: flex-start;
  transition: all 0.2s;
}

.about__connections {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.about__data {
  margin-left: 16px;
  margin-top: 24px;
  margin-right: 16px;
  line-height: normal;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.about__icons {
  font-size: 20px;
  margin-right: 12px;
}

.icon_mod {
  margin-left: 3.5px;
  margin-right: 16px;
}

.about__description {
  /* width: 60%; */
  width: max-content;
  height: 100%;
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 27px;
  color: #666; */
}

.about__welcome {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #252525;
  margin-bottom: 15px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.about__commodoreLogo {
  height: 16px;
  vertical-align: middle;
}

.about__description p {
  margin-bottom: 15px;
}

/* links */

.text_links,
.text_links:link,
.text_links:visited {
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: #8a8a8a;
}

.text_links:hover,
.text_links:active {
  color: #007bff;
}

.about__resume {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__links {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gray {
  color: gray;
}

@media screen and (max-width: 1200px) {
  .about__wrapper {
    width: 962px;
  }
  .about__image {
    max-width: 265px;
  }
}

@media screen and (max-width: 992px) {
  #about {
    justify-content: flex-start;
    align-items: center;
  }

  .about__wrapper {
    width: 738px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .about__info {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }

  .about__image {
    max-width: 400px;
    margin-bottom: 24px;
  }

  .about__connections {
    width: fit-content;
  }

  .about__description {
    margin: 24px 0;
    width: 100%;
  }

  .about__resume {
    justify-content: space-evenly;
  }

  .about__welcome {
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .about__wrapper {
    width: 100vw;
    min-width: 360px;
    padding: 15px 0 0;
  }
  .about__image {
    width: 90%;
  }
  .about__description {
    width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .about__resume {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
}
